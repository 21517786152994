import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom"
import './App.css';
import MainScene from './Components/MainScene';
import { ThemeProvider } from '@mui/material';
import CreateLoveguardTheme from './Components/Shared/CreateLoveguardTheme';
import { useTranslation } from 'react-i18next';
import QuizMainScene from './Components/Quiz/QuizMainScene';
import Login from './Components/Login/Login';
import Privacy from './Components/Privacy';
import PrivacyEn from './Components/PrivacyEn';
import TermsAndConditionsApp from './Components/TermsAndConditionsApp';
import PrivacyPolicyApp from './Components/PrivacyPolicyApp';
import DataDeletion from './Components/DataDeletion';
import AppDownload from './Components/AppDownload';
import EbookRedirect from './Components/Navigation/EbookRedirect';
import Funnel from './Components/Klicenka/Klicenka';
import Klicenka from './Components/Klicenka/Klicenka';
import Ebook from './Components/Ebook/Ebook';
import LoI from './Components/LoI/LoI';
import LoIMain from './Components/LoI/LoIMain';
import AboutUs from './Components/AboutUs';
import LoginLoI from './Components/Login/LoginLoI';
import LoginLoICompleted from './Components/Login/LoginLoiCompleted';
import { CookiesProvider } from 'react-cookie';
import CookiesScene from './CookiesScene';
import InvestorSummary from './Components/Investor Summary/InvestorSummary';
import Team from './Components/Team/TeamBig';
import PrivacyPolicyAppMulti from './Components/PrivacyPolicyAppMulti';
import ThankYouPage from './Components/ThankYou/ThankYouPage';

function App() {
  const { i18n } = useTranslation();
  const theme = CreateLoveguardTheme();
  useEffect(() => {
    i18n.changeLanguage();
  }, []);
  return (
    <div style={{height:'100%'}}>

      <ThemeProvider theme={theme}>
        <CookiesScene />
        <Routes>
          <Route path="/" element={<CookiesProvider><MainScene /></CookiesProvider>} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/ebook" element={<Ebook />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/privacypolicy" element={<PrivacyEn />} />
          <Route path="/TermsAndConditionsApp" element={<TermsAndConditionsApp />} />
          <Route path="/PrivacyPolicyApp" element={<PrivacyPolicyApp />} />
          <Route path="/PrivacyPolicyAppMulti" element={<PrivacyPolicyAppMulti />} />
          TermsAndConditionsApp
          <Route path="/datadeletion" element={<DataDeletion />} />
          <Route path="/appDownload" element={<AppDownload />} />
          <Route path="/thankYouPage" element={<ThankYouPage />} />
          <Route path="/quiz" element={<QuizMainScene />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Login singUp />} />
          <Route path="/signuploi" element={<LoginLoI singUp />} />
          <Route path="/signuploicompleted" element={<LoginLoICompleted />} />
          <Route path="/klicenka" element={<Klicenka />} />
          <Route path="/loi" element={<LoIMain />} />
          <Route path="/investorsummary" element={<InvestorSummary />} />
          <Route path="/team" element={<Team />} />
          {/* <Route path="/app" element={<div ><iframe src=''/></div>} /> */}
          <Route path="/app" element={<div style={{height:'100vh'}}><iframe seamless  style={{display:'block', width:'100%', height:'100vh', border:'0px'}}  src='https://loveguard-app.azurewebsites.net/login'/></div>} />
        </Routes>
      </ThemeProvider>
    </div>
    // </CookiesProvider>

  );
}

export default App;
