import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { FacebookOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function ThankYouPage() {
    const navigate = useNavigate();

    return (
        <Box 
            sx={{
                display: 'flex', 
                flexDirection: 'column', 
                gap: '16px', 
                maxWidth: '600px',
                margin: 'auto', 
                textAlign: 'center', 
                padding: '40px'
            }}
        >
            <Typography variant='h3' color='primary.main'>
                Super, ebook už máš ve svém mailu. 👍
            </Typography>
            <Typography variant='body1'>
                A pokud chceš pro svůj vztah udělat ještě víc, přidej se do facebookové skupiny 
                a buď součástí správně chlapský komunity.💪
            </Typography>
            <Button 
                variant='contained' 
                href="https://www.facebook.com/groups/804023694343382" 
                target="_blank" 
                startIcon={<FacebookOutlined/>}
            >
                Přidat se do skupiny
            </Button>

            <Button variant="text" onClick={() => navigate('/')}>
                Zpět na hlavní stránku
            </Button>
        </Box>
    );
}
