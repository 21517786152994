import React, { useState, useEffect } from 'react'
import ebookbait from '../../Assets/EbookBait.png';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment'
import MailOutline from '@mui/icons-material/MailOutline';
import { useMediaPredicate } from 'react-media-hook';
import { LoveguardApiClient } from '../../Api/ApiBaseClient';
import { EbookRequest } from '../../Api/ApiServer';
import { useNavigate } from 'react-router-dom';
import { IsAndroid } from '../../Components/Shared/IsAndroid';
import { IsIos } from '../../Components/Shared/IsIos';
import Navbar from '../Navigation/Navbar';
import { t } from 'i18next';
import { FacebookOutlined, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';



export default function Ebook() {
    const [mail, setMail] = useState<string>('');
    const [textOdeslano, setTestOdeslano] = useState<string>('Získat Ebook');

    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const delayInMilliseconds = 1000;
    const navigate = useNavigate();



    const redirectToShop = () => {
        if (IsAndroid()) {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.Loveguard.App'; // Replace with your actual Google Play Store URL
        } else if (IsIos()) {
            // Redirect to Apple App Store if the user is on iOS
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134'; // Replace with your actual Apple App Store URL
        } else {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134?platform=iphone'; // Replace with your actual Google Play Store URL;
        }
    }

    useEffect(() => {
        // Posune stránku na začátek při každém renderování komponenty
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
            <Navbar />

            <Box sx={{ display: 'flex', flexDirection: 'column', px: biggerThan700 ? '128px' : '16px', py: '80px', gap: '40px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Typography variant='h3' color='primary.main' fontWeight='bold'>
                        Objev tajemství harmonického vztahu
                    </Typography>
                    <Typography variant='body1' fontWeight='bold'>
                        Stáhni zdarma ebook s tipy, které změní váš pohled na partnerství i Tvoji partnerku.
                    </Typography>
                    <Button variant='contained' sx={{ maxWidth: '200px' }}
                        onClick={() => {
                            const formEmailElement = document.getElementById('formEmail');
                            if (formEmailElement) {
                                formEmailElement.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}>
                        Stáhnout tipy
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Typography variant='h4'>
                        Hledáš způsob, jak zlepšit komunikaci, vybudovat hlubší důvěru a vrátit vášeň do svého vztahu?
                    </Typography>
                    <Typography variant='body1'>
                        Stahuj ebook plný praktických tipů k posílení vztahu.
                    </Typography>
                    <Typography variant='h5' color='primary.main' fontWeight='bold'>
                        Co v ebooku najdeš?
                    </Typography>
                    <ul style={{ display: 'flex', flexDirection: 'column', paddingLeft: '24px', gap: '12px' }}>
                        <li>
                            <b style={{ fontWeight: 'bold' }}>Gesta lásky</b> - Pochop, jakým způsobem Tvá partnerka vyjadřuje a přijímá lásku.
                        </li>
                        <li>
                            <b style={{ fontWeight: 'bold' }}>Tipy k efektivní komunikaci</b> - Nauč se, jak mluvit, naslouchat a skutečně se chápat.
                        </li>
                        <li>
                            <b style={{ fontWeight: 'bold' }}>Tipy na zvládání konfliktů</b> - Proměň hádky v příležitost k růstu.
                        </li>
                        <li>
                            <b style={{ fontWeight: 'bold' }}>Inspirace pro intimitu a romantiku</b> - Nápady na prohlubování vášně ve vztahu.
                        </li>
                    </ul>

                    <Typography variant='h5' color='primary.main' fontWeight='bold'>
                        Pro koho je e-book určen?
                    </Typography>
                    <ul style={{ display: 'flex', flexDirection: 'column', paddingLeft: '24px', gap: '12px' }}>
                        <li>
                            Pro páry, které chtějí upevnit svůj vztah.

                        </li>
                        <li>
                            Pro muže, kteří hledají cestu k lepším vztahům.
                        </li>
                        <li>
                            Pro každého, kdo věří, že vztahy si zaslouží péči.
                        </li>
                    </ul>

                    <Typography variant='h5'>
                        Stáhni ebook zdarma a udělej první krok k silnějšímu vztahu ještě dnes!
                    </Typography>
                </Box>



                <div id='formEmail' style={{
                    borderRadius: '16px', boxShadow: '0px -2px 16px #F34848',
                    textAlign: 'center', width: '100%', height: 'auto', backgroundColor: '#F5F5F5', padding: '20px'

                }}>
                    <div>
                        <Typography variant='h4' color='#404040'>Pošleme vám odkaz na Ebook emailem.</Typography>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <TextField placeholder='Zde zadejte email'
                            value={mail}
                            onChange={(e) => { setMail(e.currentTarget.value) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MailOutline />
                                    </InputAdornment>
                                ),
                            }}
                            required id="outlined-basic" label="Emailová Adresa" variant="outlined"></TextField>
                    </div>
                    <div>
                        <Button disabled={mail === ''} onClick={() => { new LoveguardApiClient().GetClientToAuth().sendEbookMail(new EbookRequest({ email: mail })).then(() => { setTestOdeslano('Úspěšně odeslán'); navigate('/thankYouPage'); }) }} placeholder="Zadejte váš email" size='large' variant='contained' style={{ marginTop: '20px' }} >{textOdeslano}</Button>
                    </div>

                </div>

            </Box>
            <div id="contact" style={{ height: 'auto', width: '100%', background: '#272727', zIndex: 1000 }}>

                <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '2em' }}>
                    <Button target="_blank" href='https://www.facebook.com/profile.php?id=100089619233058'><FacebookOutlined></FacebookOutlined></Button>
                    <Button target="_blank" href='https://www.instagram.com/loveguard_official/'><Instagram></Instagram></Button>
                    <Button target="_blank" href='https://twitter.com/Loveguard_tweet'><Twitter></Twitter></Button>
                    <Button target="_blank" href='https://www.youtube.com/@loveguard6430'><YouTube></YouTube></Button>
                    <Button target="_blank" href='https://www.linkedin.com/company/loveguard/'><LinkedIn></LinkedIn></Button>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                    <Box minWidth='300px'>
                        <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>{t('Contact')}</Typography>
                        <div>
                            <Typography variant='body1' marginLeft='6%' color='white'>Jakub Forman</Typography>
                        </div>
                        <div>
                            <Typography variant='body1' marginLeft='6%' color='white'>forman@loveguard.cloud</Typography>
                        </div>
                        <div style={{ marginTop: '40px', marginLeft: '6%' }}>
                            <Button variant='text' size='small' onClick={() => { window.open('/TermsAndConditionsApp', '_blank') }}>Smluvní podmínky</Button>
                        </div>
                        <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                            <Button variant='text' size='small' onClick={() => { window.open('/PrivacyPolicyApp', '_blank') }}>Zásady ochrany osobních údajů</Button>
                        </div>
                    </Box>
                    <Box minWidth='300px'>
                        <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Společnost</Typography>
                        <div>
                            <Typography variant='body1' marginLeft='6%' color='white'>LOVEGUARD s.r.o.</Typography>
                        </div>
                        <div>
                            <Typography variant='body1' marginLeft='6%' marginBottom='40px' color='white'>IČO: 21908451</Typography>
                        </div>

                    </Box>

                    <Box minWidth='300px'>
                        <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Pro Média</Typography>

                        <div style={{ marginLeft: '6%' }}>
                            <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/Erro5FSWYJhCumBMFMXNhuABS0I8DMPOQAKeHCAczpqT3w?e=a9wNAM', '_blank') }}>Loga a fotky</Button>
                        </div>
                        <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                            <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/EgefSryjT5JOpSEgeeobFc0BGBFn5nraZ8r47duFLtCMbQ?e=d83xZ2', '_blank') }}>Zmínky o nás</Button>
                        </div>

                    </Box>

                    <Box minWidth='300px'>
                        <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Mapa Stránek</Typography>

                        <div style={{ marginLeft: '6%' }}>
                            <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/') }}>Aplikace</Button>
                        </div>
                        <div style={{ marginLeft: '6%' }}>
                            <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/AboutUs') }}>O nás</Button>
                        </div>
                        <div style={{ marginLeft: '6%' }}>
                            <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/#vyzvy') }}>Výzvy</Button>
                        </div>
                        <div style={{ marginLeft: '6%' }}>
                            <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/quiz') }}>Kvíz</Button>
                        </div>
                        <div style={{ marginLeft: '6%' }}>
                            <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { window.open('/ebook') }}>Ebook</Button>
                        </div>
                        <div style={{ marginLeft: '6%' }}>
                            <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/#contact') }}>Kontakt</Button>
                            {/* <Button variant='text' size='small' onClick={() => {
                                    const newWindow = window.open('/'); // Otevře hlavní stránku
                                    if (newWindow) {
                                        newWindow.onload = () => {
                                            newWindow.location.hash = '#contact'; // Nastaví hash až po načtení
                                        };
                                    }
                                }}>Kontakt</Button> */}
                        </div>
                        <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                            <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={redirectToShop}>Stáhnout APP</Button>
                        </div>

                    </Box>
                </Box>
            </div>
        </div>
    )
}
